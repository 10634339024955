
import { defineComponent, ref, inject } from "vue";
import TopicItem from "@/components/common/TopicItem.vue";
import ClassItem from "@/components/common/ClassItem.vue";
import dateFormat from "@/utils/dateFormat";
import Empty from "@/components/common/Empty.vue";
export default defineComponent({
  name: "personalMine",
  props: {
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    noInfo: {
      type: String,
      default: () => {
        return '访客'
      }
    }
  },
  components: {
    TopicItem,
    ClassItem,
    Empty,
  },
  setup() {
    const userInfo = inject("userInfo") as any;
    return {
      userInfo,
      dateFormat,
    };
  },
});
